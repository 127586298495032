var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "doc-container" }, [
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "title" }, [_vm._v("用户注册协议")]),
      _vm._m(0),
      _c(
        "div",
        { staticStyle: { "text-align": "center", "padding-bottom": "20px" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.approval(true)
                },
              },
            },
            [_vm._v("确认并且接受")]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.approval(false)
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "article" }, [
      _c("p", { staticClass: "article-title" }, [
        _c("strong", [_vm._v("1.【铝拍拍平台简介】")]),
      ]),
      _c("p", [
        _vm._v(
          "铝拍拍平台由佛山南海铝拍拍科技有限公司运营，是将铝产业链交易平台化，铝产业为中小微企业赋能升级，坚持以客户为中心，通过整合铝产业链各方资源，集交易、大数据，推动产业链上中下游，大中小企业融通创新的第三方互联网平台。铝拍拍平台主要通过B2B的交易形式，为用户提供大宗商品一站式的交易体验。"
        ),
      ]),
      _c("p", { staticClass: "article-title" }, [
        _c("strong", [_vm._v("2.【铝拍拍平台宗旨】")]),
      ]),
      _c("p", [
        _vm._v(
          "铝拍拍平台作为铝产业链产品交易的服务者、基础设施的提供者和信用体系的构建者，将持续提升全品类、全流程、全业务、全场景服务能力，建设平台化、智慧化、生态化基础设施，构建多维度、数据化、可视化信用体系，打造产业互联网平台，构建综合型+特色型+专业型产业互联网平台生态圈。"
        ),
      ]),
      _c("p", { staticClass: "article-title" }, [
        _c("strong", [_vm._v("3.【铝拍拍平台规则】")]),
      ]),
      _c("p", [
        _vm._v(
          "佛山南海铝拍拍科技有限公司为维护铝拍拍平台用户的合法权益，保障铝拍拍平台的正常运营，根据《中华人民共和国民法典》《中华人民共和国电子商务法》等相关法律法规制定平台规则总则（以下简称“"
        ),
        _c("strong", [_vm._v("本总则")]),
        _vm._v("”）及其他平台规则。"),
      ]),
      _c("p", [
        _vm._v(
          "用户应认真阅读并遵守本总则及其他任何适用的平台规则。用户务必审慎阅读、充分理解铝拍拍平台的任何平台规则的各项条款内容，特别是免除或者限制铝拍拍方责任的条款、争议解决和法律适用条款。除非用户已阅读并接受铝拍拍平台规则的所有条款，否则用户无权使用铝拍拍平台服务。用户使用铝拍拍平台服务即视为用户已充分理解并同意本总则和其他平台规则的约束。"
        ),
      ]),
      _c("p", { staticClass: "article-title" }, [
        _c("strong", [_vm._v("4.【铝拍拍平台规则概览】")]),
      ]),
      _c("p", [_vm._v("铝拍拍平台规则主要包括如下三个层次的管理规范：")]),
      _c("p", [
        _c("strong", [_vm._v("平台规则总则：")]),
        _vm._v(
          "平台规则总则是平台规则的纲要性文件，概览性地介绍了各平台规则的内容、适用范围和效力级别，以及规范了平台规则中各项专业术语所适用的定义。"
        ),
      ]),
      _c("p", [
        _c("strong", [_vm._v("用户规则和交易规则：")]),
        _vm._v(
          "用户规则是在平台规则总则基础上对用户注册与管理方面作出的具体规定，包括"
        ),
        _c("u", [_vm._v("《用户注册协议》")]),
        _c("u", [_vm._v("《隐私和信息保护政策》")]),
        _vm._v(
          "；交易规则是在平台规则总则基础上对利用铝拍拍平台进行商品交易行为作出的具体规定，包括"
        ),
        _c("u", [_vm._v("《买家交易规则》")]),
        _c("u", [_vm._v("《卖家交易规则》")]),
        _vm._v("。"),
      ]),
      _c("p", [
        _c("strong", [_vm._v("平台公告：")]),
        _vm._v(
          "铝拍拍平台在遵守法律法规的强制性规定的前提下，有权根据市场行情、平台管理、平台整体规划及相关要求、平台现有功能等因素，针对特定商品或服务、在特定地区、特定时限内，针对铝拍拍平台运营以及买家和卖家使用铝拍拍平台达成交易有关的事项不时做出的公示说明，该等公告内容构成平台规则不可分割的一部分。平台公告不具有溯及力，仅对在公告期间发生的法律行为生效。"
        ),
      ]),
      _c("p", { staticClass: "article-title" }, [
        _c("strong", [_vm._v("5.【平台规则适用范围】")]),
      ]),
      _c("p", [_vm._v("铝拍拍平台各规则的适用范围如下：")]),
      _c("p", [
        _c("strong", [_vm._v("平台规则总则：")]),
        _vm._v(
          "适用于铝拍拍平台各方（包括用户、买家、卖家、其他相关者、佛山南海铝拍拍科技有限公司等）在铝拍拍平台上的一切行为，包括任何交易行为与非交易行为。"
        ),
      ]),
      _c("p", [
        _c("strong", [_vm._v("用户注册协议：")]),
        _vm._v(
          "适用于用户在铝拍拍平台上的账户注册、服务使用、服务终止及账户注销。"
        ),
      ]),
      _c("p", [
        _c("strong", [_vm._v("隐私和信息保护政策：")]),
        _vm._v("适用于铝拍拍平台上用户信息的获取、使用、管理、保护等事宜。"),
      ]),
      _c("p", [
        _c("strong", [_vm._v("买家交易规则：")]),
        _vm._v("适用于买家在商品交易项下购买商品的行为。"),
      ]),
      _c("p", [
        _c("strong", [_vm._v("卖家交易规则：")]),
        _vm._v("适用于卖家在商品交易项下出售商品的行为。"),
      ]),
      _c("p", [
        _c("strong", [_vm._v("平台公告：")]),
        _vm._v("适用于铝拍拍平台不时通过公告规定的特定事项。"),
      ]),
      _c("p", { staticClass: "article-title" }, [
        _c("strong", [_vm._v("6.【效力级别】")]),
      ]),
      _c("p", [
        _vm._v(
          "平台规则总则是对铝拍拍平台宗旨、基础、规则框架的概括性和原则性规定。具体各领域相关的平台规则，应当在不损害平台规则总则规定的前提下，予以适用。"
        ),
      ]),
      _c("p", [
        _vm._v(
          "平台公告是铝拍拍平台就特定商品或服务、在特定地区、特定时限内不时作出的特别规定，在平台公告所明示的范围内，平台公告优先于用户规则和交易规则。"
        ),
      ]),
      _c("p", { staticClass: "article-title" }, [
        _c("strong", [_vm._v("7.【铝拍拍平台规则定义】")]),
      ]),
      _c("p", [
        _vm._v(
          "除各平台规则另有规定外，（1）平台规则中使用的所称条款均指该平台规则项下的条款，（2）所有条款均通过平台规则的任何引用而专门地纳入并成为该平台规则的一部分，（3）“包括”和“包含”应被解释为“包括/包含但不限于”。"
        ),
      ]),
      _c("p", [_vm._v("本总则及各平台规则中提及的术语应当具有以下含义：")]),
      _c("p", [
        _c("strong", [_vm._v("柏")]),
        _c("strong", [_vm._v("斯基：")]),
        _vm._v("指佛山南海铝拍拍科技有限公司。"),
      ]),
      _c("p", [
        _c("strong", [_vm._v("柏")]),
        _c("strong", [_vm._v("斯基方：")]),
        _vm._v("指佛山南海铝拍拍科技有限公司及其关联方。"),
      ]),
      _c("p", [
        _c("strong", [_vm._v("关联方：")]),
        _vm._v(
          "指对于任何主体而言，其直接或间接控制的任何其他实体，或者直接或间接地控制该主体或与该主体共同受控制于他人的任何其他实体。“控制”或“受控制”指，通过表决权、合约或其他方式，直接或间接地拥有该主体超过50%以上股权，或对该主体拥有任命董事会、管理层或同等决策机构的管理和决策的权力，或事实上构成实际控制的其他关系。如果该主体是自然人，指其任何直系亲属，包括其配偶、子女、父母、祖父母、或兄弟姐妹。"
        ),
      ]),
      _c("p", [
        _c("strong", [_vm._v("铝拍拍平台：")]),
        _vm._v("指域名为www. lv-paipai.com的网站及相应的官方移动客户端。"),
      ]),
      _c("p", [
        _c("strong", [_vm._v("买家：")]),
        _vm._v("指通过铝拍拍平台采购商品的用户。"),
      ]),
      _c("p", [
        _c("strong", [_vm._v("卖家：")]),
        _vm._v("指通过铝拍拍平台销售商品的用户。"),
      ]),
      _c("p", [
        _c("strong", [_vm._v("铝拍拍平台服务：")]),
        _vm._v(
          "指用户访问铝拍拍平台或利用铝拍拍平台达成商品交易、使用铝拍拍平台产品或服务、使用铝拍拍平台的移动客户端或者使用佛山南海铝拍拍科技有限公司提供的与前述任一项相关的网站或软件时佛山南海铝拍拍科技有限公司向用户提供的产品和服务。"
        ),
      ]),
      _c("p", [
        _c("strong", [_vm._v("平台规则：")]),
        _vm._v("指本总则、"),
        _c("u", [_vm._v("《用户注册协议》")]),
        _c("u", [_vm._v("《隐私和信息保护政策》")]),
        _c("u", [_vm._v("《买家交易规则》")]),
        _c("u", [_vm._v("《卖家交易规则》")]),
        _vm._v(
          "、平台公告及铝佛山南海铝拍拍科技有限公司不时制定的适用于铝拍拍平台的其他规则。"
        ),
      ]),
      _c("p", [
        _c("strong", [_vm._v("认证用户：")]),
        _vm._v("指与佛山南海铝拍拍科技有限公司签订"),
        _c("u", [_vm._v("《用户注册协议》")]),
        _vm._v(
          "，已提交相关证明文件并经佛山南海铝拍拍科技有限公司审核，认证通过的用户，包括个人用户及企业用户。"
        ),
      ]),
      _c("p", [
        _c("strong", [_vm._v("个人用户：")]),
        _vm._v("指注册成功后的自然人用户。"),
      ]),
      _c("p", [
        _c("strong", [_vm._v("企业用户：")]),
        _vm._v("指认证通过的企业法人或其他组织用户。"),
      ]),
      _c("p", [
        _c("strong", [_vm._v("游客：")]),
        _vm._v("指未经注册认证的用户。"),
      ]),
      _c("p", [
        _c("strong", [_vm._v("店铺：")]),
        _vm._v(
          "指卖家在铝拍拍平台上开设的，能够让买家浏览、购买交易全过程的虚拟店铺。同一卖家在铝拍拍平台上只能开设一个店铺。"
        ),
      ]),
      _c("p", [
        _c("strong", [_vm._v("用户信息：")]),
        _vm._v(
          "指用户在使用铝拍拍平台服务时向佛山南海铝拍拍科技有限公司提供的信息，包括但不限于个人识别信息、个人背景信息、设备信息、订单信息、操作信息等。"
        ),
      ]),
      _c("p", [
        _c("strong", [_vm._v("挂货")]),
        _c("strong", [_vm._v("：")]),
        _vm._v(
          "指卖方根据平台规则的要求在铝拍拍平台上发布出售特定商品的要约的行为。"
        ),
      ]),
      _c("p", [
        _c("strong", [_vm._v("平台公告：")]),
        _vm._v(
          "指铝拍拍平台在遵守法律法规的强制性规定的前提下，根据市场行情、平台管理、平台整体规划及相关要求、平台现有功能等因素，针对特定商品或服务、在特定地区、特定时限内，针对平台规则及其他与铝拍拍平台运营以及买家和卖家使用铝拍拍平台达成交易有关的事项不时做出的公示说明。"
        ),
      ]),
      _c("p", [
        _c("strong", [_vm._v("日：")]),
        _vm._v(
          "指日历日，为当日00:00:00至23:59:59，且遇周末和法定节假日不顺延。"
        ),
      ]),
      _c("p", [
        _c("strong", [_vm._v("工作日：")]),
        _vm._v("指排除星期六、星期日、中国法定节假日之外的任何一个日历日。"),
      ]),
      _c("p", { staticClass: "article-title" }, [
        _c("strong", [_vm._v("8.【附则】")]),
      ]),
      _c("p", { staticClass: "article-title" }, [
        _c("strong", [_vm._v("8.1 ")]),
        _vm._v(
          "通知：铝拍拍方可以通过电子邮件、手机短信、传真、APP推送、铝拍拍平台即时通讯工具的方式向用户在铝拍拍平台注册时提供的电子邮件地址、手机号码、传真号进行通知。通过电子邮件、手机短信、APP推送或铝拍拍平台即时通讯工具方式进行通知，送达时间以相关电子邮件、手机短信、推送或通讯内容在铝拍拍方系统中记载的发出时间为准；通过传真方式进行通知，送达时间为相关传真的发出时间。同时，铝拍拍方也有权通过铝拍拍平台以公告的方式通知用户与任何铝拍拍平台项下产品或服务有关的任何事宜，用户有义务不时关注铝拍拍平台的公告信息。公告或通知与本协议不一致的，以公告或通知的内容为准。"
        ),
      ]),
      _c("p", { staticClass: "article-title" }, [
        _c("strong", [_vm._v("8.2 ")]),
        _vm._v(
          "法律适用及争议解决：本总则的成立、生效、履行、解释及纠纷解决，适用中华人民共和国法律（不包括其法律适用法）。用户和铝拍拍方因本总则产生的，或与本总则相关的任何纠纷或争议，首先应友好协商解决。协商不成的，有关方应当将纠纷或争议提交至佛山南海铝拍拍科技有限公司企业注册所在地的人民法院通过诉讼的方式解决。"
        ),
      ]),
      _c("p", { staticClass: "article-title" }, [
        _c("strong", [_vm._v("8.3 ")]),
        _vm._v(
          "规则的生效与变更：本总则于2021年 月  日公示，公示期为7日，公示期满后于2021年  月  日实施生效。在符合《电子商务法》或其他适用法律规定的公示要求或其他强制性要求的前提下，铝拍拍方有权根据需要不时地重述、修改本总则，并以在铝拍拍平台公告的方式通知买方。如不同意相关变更的，用户必须立即停止任何使用铝拍拍平台的行为。用户注册和/或使用铝拍拍平台的行为即构成用户对公告及所涉相关规则变更（无论该等规则是否以弹窗形式单独要求买家确认）的无条件确认与接受。变更生效后的本总则对该等规则变更生效前的各方发生行为或该等行为产生的法律后果均不具有溯及力。"
        ),
      ]),
      _c("p", [_vm._v("版本 1.0")]),
      _c("p", [_vm._v("生效日期 2021-07-30")]),
      _c("p", [_vm._v(" ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }